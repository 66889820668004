import React from 'react'
import PropTypes from 'prop-types'
import Navbar from '../partials/NavBar/Navbar'
import useSiteMetadata from '../graphqlFragments/SiteMetadata'
import { Footer } from '..'
import Head from './Head'
import headerProps from '../propTypes/headerProps'
import PrimaryHeader from '../elements/PrimaryHeader/PrimaryHeader'

const LayoutFullWidth = ({ header, children }) => {
  const { title, description } = useSiteMetadata()
  const headerProps = {
    ...header,
    image: header.backgroundUrl,
  }

  return (
    <>
      <Head title={title} description={description} />
      <Navbar />
      <PrimaryHeader {...headerProps} />
      {children}
      <Footer />
    </>
  )
}

LayoutFullWidth.propTypes = {
  header: headerProps,
  children: PropTypes.node,
}

export default LayoutFullWidth
