import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { path, groupBy, pathOr, last, take } from 'ramda'
import { format } from 'date-fns'

import { flattenNodes } from '../../utils'
import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  Breadcrumbs,
  Typography,
  TransitionLink,
  MdxWrapper,
  PressReleaseExcerpt,
  PinBlockGroup,
  Tabs,
} from '../../components'
import Tab from '../../components/elements/Tabs/Tab'
import TabList from '../../components/elements/Tabs/TabList'
import TabPanel from '../../components/elements/Tabs/TabPanel'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styles from './PressReleaseCategoriesTemplate.module.css'

const groupPressReleasesByCategory = groupBy(
  path(['node', 'frontmatter', 'category']),
)

const PressReleaseCategoriesTemplate = ({ data }) => {
  const {
    category: {
      frontmatter: { title, editor, header },
    },
    pressReleases,
  } = data

  const groupedPressReleases = groupPressReleasesByCategory(pressReleases.edges)

  const releases = flattenNodes(pathOr([], [title], groupedPressReleases))

  const releasesByYear = groupBy(r => r.publishedDate.split('-')[0], releases)

  return (
    <LayoutFullWidth header={header}>
      <div className="page-wrapper container">
        <Breadcrumbs>
          <Breadcrumbs.Item>
            <TransitionLink to="/about-us/">About Us</TransitionLink>
          </Breadcrumbs.Item>
          <Breadcrumbs.Item>
            <TransitionLink to="/about-us/press-office/">
              Press Office
            </TransitionLink>
          </Breadcrumbs.Item>
          <Breadcrumbs.Item>
            <span>{title}</span>
          </Breadcrumbs.Item>
        </Breadcrumbs>

        <Divider thickness={4} />
        <MdxWrapper>
          <Typography tag="h2" type="boxHeading">
            Press Office: {title}
          </Typography>

          <div className={styles.content}>
            <MDXRenderer>{editor}</MDXRenderer>
          </div>

          {Object.keys(releasesByYear).length > 0 && (
            <div>
              <Tabs initialValue={last(Object.keys(releasesByYear))}>
                <TabList>
                  {Object.keys(releasesByYear).map(year => (
                    <Tab key={year} identifier={year} kind="link">
                      {year}
                    </Tab>
                  ))}
                </TabList>

                {Object.keys(releasesByYear).map(year => (
                  <TabPanel key={year} identifier={year}>
                    <PinBlockGroup columns={2} spacing="lg">
                      {take(4, releasesByYear[year]).map((r, i) => {
                        const link = r.externalUrl ? (
                          <a
                            href={r.externalUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {r.title}
                          </a>
                        ) : (
                          <TransitionLink to={r.slug}>{r.title}</TransitionLink>
                        )
                        return (
                          <PressReleaseExcerpt
                            key={i}
                            title={link}
                            excerpt={r.excerpt}
                            thumbnailSrc={r.featuredImage}
                            date={format(
                              new Date(r.publishedDate),
                              'dd MMMM yyyy',
                            )}
                          />
                        )
                      })}
                    </PinBlockGroup>
                  </TabPanel>
                ))}
              </Tabs>

              <Divider thickness={4} />
            </div>
          )}
        </MdxWrapper>
      </div>
    </LayoutFullWidth>
  )
}

PressReleaseCategoriesTemplate.propTypes = {
  data: PropTypes.shape({
    category: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
        title: PropTypes.string,
        editor: PropTypes.any,
      }),
    }),
    pressReleases: PropTypes.arrayOf(PropTypes.any),
  }),
}

export default PressReleaseCategoriesTemplate

/**
 * @todo filter press releases by category. Only have access to slug though, so can't work atm
 */
export const pageQuery = graphql`
  query PressReleaseCategoriesTemplate($slug: String!) {
    category: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        title
        editor
      }
    }
    pressReleases: allMdx(
      filter: { fields: { contentType: { eq: "pressReleases" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            category
            excerpt
            publishedDate
            externalUrl
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 100) {
                  aspectRatio
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
